import { FormEvent, useState } from "react"
import styled from "styled-components"

import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useIsValueDirty } from "src/hooks/useIsValueDirty"
import { useTranslate } from "src/i18n/useTranslate"
import { DiscardChangesDialog } from "src/ui/Dialog/DiscardChangesDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MDivider } from "src/ui/Divider/Divider"
import { spacing } from "src/ui/spacing"

import { PresetSectionToggle } from "./PresetSectionToggle"

const FORM_ID = "noise-thresholds-form"

export function NoiseThreholdsDialog({
  open,
  ...props
}: {
  onClose: () => void
  settings: TNoiseMonitoringPreset
  open: boolean
}) {
  if (!open) return null
  return <NoiseThreholdsDialogOpen {...props} />
}

function NoiseThreholdsDialogOpen({
  onClose,
  settings,
}: {
  onClose: () => void
  settings: TNoiseMonitoringPreset
}) {
  const { t, langKeys, tPlaceholder } = useTranslate()
  const [showConfirm, setShowConfirm] = useState(false)

  const quietHours = useIsValueDirty({
    initial: settings.indoor_noise_threshold?.quiet_hours_enabled ?? false,
  })
  const dirty = [quietHours].some((item) => item.isDirty)

  function handleDiscardConfirmed() {
    setShowConfirm(false)
    onClose()
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    onClose()
  }

  function handleClose() {
    if (dirty) {
      setShowConfirm(true)
    } else {
      onClose()
    }
  }

  const error = undefined
  const isLoading = false

  return (
    <MDialog
      open={true}
      onClose={handleClose}
      title={tPlaceholder("Thresholds and duration")}
      description={tPlaceholder("Select when you want to be alerted to noise.")}
      hideClose
      formId={FORM_ID}
      confirmLabel={t(langKeys.save)}
      loading={isLoading}
      error={error}
    >
      {showConfirm && (
        <DiscardChangesDialog
          open={showConfirm}
          onClose={() => {
            setShowConfirm(false)
          }}
          onConfirm={handleDiscardConfirmed}
        />
      )}

      <Form id={FORM_ID} onSubmit={handleSubmit}>
        <div>Noise threshold settings go here</div>

        <MDivider />

        <div>Noise duration settings go here</div>

        <MDivider />

        <PresetSectionToggle
          title={t(langKeys.quiet_hours)}
          state={quietHours.value}
          setState={(value) => quietHours.setValue(value)}
          infoTooltipContents={tPlaceholder(
            `Enable if you want to set different thresholds for parts of the
            day`
          )}
        >
          Quiet hour settings go here
        </PresetSectionToggle>
      </Form>
    </MDialog>
  )
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.M};
`
