import styled from "styled-components"

import { RouteNotFound } from "src/components/RouteNotFound/RouteNotFound"
import { MonitoringPresetsNoiseSettings } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetsNoiseSettings"
import { useMonitoringPresetBreadcrumbs } from "src/components/SettingsProfiles/MonitoringPresets/useMonitoringPresetBreadcrumbs"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchNoiseMonitoringPreset } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function MonitoringPresetsNoiseEdit({ presetId }: { presetId: string }) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const fetchNoiseMonitoringPreset = useFetchNoiseMonitoringPreset({
    orgId,
    noisePresetId: presetId,
  })
  const breadcrumbs = useMonitoringPresetBreadcrumbs({
    editName: fetchNoiseMonitoringPreset.data?.name ?? "...",
  })

  if (fetchNoiseMonitoringPreset.isError) {
    return <RouteNotFound />
  }

  const presetData = fetchNoiseMonitoringPreset.data
  const numberHomes = fetchNoiseMonitoringPreset.data?.number_of_homes
  const title = fetchNoiseMonitoringPreset.isLoading ? (
    <MSkeleton width={300} />
  ) : (
    fetchNoiseMonitoringPreset.data.name
  )
  const description = fetchNoiseMonitoringPreset.isLoading ? (
    <MSkeleton width={200} />
  ) : (
    `Settings apply to ${numberHomes} rental units`
  )

  const tabs: ITab[] = [
    {
      label: t(langKeys.settings),
      id: "settings",
      view: presetData ? (
        <MonitoringPresetsNoiseSettings presetData={presetData} />
      ) : null,
    },
    {
      label:
        t(langKeys.home, { count: 10 }) +
        (numberHomes != null ? ` (${numberHomes})` : ""),
      id: "rental_units",
      view: <MonitoringPresetsNoiseRentalUnitsView />,
    },
  ]

  return (
    <BreadcrumbView
      breadcrumbs={breadcrumbs.noiseEdit}
      title={title}
      description={description}
    >
      <Tabs labels={tabs}>
        {tabs.map(({ id, view }) => (
          <TabPanel key={id}>
            <TabContentBox>{view}</TabContentBox>
          </TabPanel>
        ))}
      </Tabs>
    </BreadcrumbView>
  )
}

const TabContentBox = styled.div`
  margin: ${spacing.L} 0;
`

function MonitoringPresetsNoiseRentalUnitsView() {
  return <div>Implement Rental Units View</div>
}
