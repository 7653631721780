import styled from "styled-components"

import { PresetSettingCardNoiseThresholds } from "src/components/SettingsProfiles/MonitoringPresets/MonitoringPresetCards/PresetSettingCardNoiseThresholds"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type MonitoringPresetsNoiseSettingsProps = {
  hidden?: boolean
  presetData: TNoiseMonitoringPreset
}

export function MonitoringPresetsNoiseSettings({
  hidden,
  presetData,
}: MonitoringPresetsNoiseSettingsProps) {
  const { tPlaceholder } = useTranslate()

  if (hidden) return null

  return (
    <CardsContainer>
      <MText variant="heading2" marginBottom={spacing.XS}>
        {tPlaceholder("Noise thresholds")}
      </MText>

      <PresetSettingCardNoiseThresholds presetData={presetData} />
    </CardsContainer>
  )
}

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`
